// src/components/ProjectsGrid.js
import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle
} from 'react';
import './ProjectsGrid.css';

const MOVEMENT_THRESHOLD = 2; // pixels allowed before cancelling
const HOVER_DELAY = 250;      // milliseconds to trigger the dimming effect

// GridItem component for each project.
// It preloads the image and fades it in once loaded.
// Updated to use IntersectionObserver for lazy loading images.
const GridItem = forwardRef(({
  project,
  idx,
  onItemClick,
  onItemMouseEnter,
  onItemMouseMove,
  onItemMouseLeave,
  opacity
}, ref) => {
  const [loaded, setLoaded] = useState(false);
  // Added state to track if image is in view.
  const [isInView, setIsInView] = useState(false);
  const imgRef = useRef(null);
  
  // Define the base URL for Amazon S3 and construct the full URL.
  // We remove "gridTest/" from the stored path and force the extension to ".JPEG" (legacy requirement).
  const s3BaseUrl = "https://arranigoe-website-grid-images.s3.us-east-2.amazonaws.com/";
  const imageUrl = s3BaseUrl + project.project_coverImage
    .replace("gridTest/", "")
    .replace(/\.jpeg$/i, ".JPEG")
    .replace(/\.png$/i, ".JPEG");
  
  // Use IntersectionObserver to set isInView when the image is near viewport.
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsInView(true);
            observer.unobserve(entry.target);
          }
        });
      },
      { rootMargin: "200px" } // load images slightly before they come into view
    );
    if (imgRef.current) {
      observer.observe(imgRef.current);
    }
    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={ref}
      className="grid-item fade-in-item"
      onMouseEnter={(e) => onItemMouseEnter(e, project.project_id)}
      onMouseMove={(e) => onItemMouseMove(e, project.project_id)}
      onMouseLeave={onItemMouseLeave}
      onClick={() => onItemClick(project.project_id)}
      style={{
        opacity: opacity,
        animationDelay: `${idx * 0.1}s`
      }}
    >
      <div className="grid-image-wrapper">
        {/*
          Updated image source:
          Instead of always setting the src via require(), we now use our custom lazy loading.
          The src is set only if the image is in view (as determined by the IntersectionObserver).
          This defers loading images off-screen, while keeping all grid items in the DOM.
          Old code reference:
          src={require(`../assets/${project.project_coverImage.replace('.jpeg','.JPEG').replace('.png','.JPEG')}`)}
        */}
        <img
          ref={imgRef}
          className="grid-image"
          src={isInView ? imageUrl : undefined}
          alt={project.project_name}
          onLoad={() => setLoaded(true)}
          onError={(e) => e.preventDefault()}
          style={{
            opacity: loaded ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out'
          }}
          loading="lazy"
        />
        <div className="grid-title">
          <span className="grid-title-line">{project.project_name.toUpperCase()}</span><br/>
          <span className="grid-title-line">{project.project_director.toUpperCase()}</span><br/>
          <span className="grid-title-line">{project.project_company.toUpperCase()}</span>
        </div>
      </div>
    </div>
  );
});

const ProjectsGrid = forwardRef(({ allProjects, searchTerm, error, isTreatmentView, onTreatmentView }, ref) => {
  const [focusedProject, setFocusedProject] = useState(null);
  const [visibleProjects, setVisibleProjects] = useState([]);
  // extraLinksVisible remains internal to control the expanded link tree
  const [extraLinksVisible, setExtraLinksVisible] = useState(false);

  const hoverTimer = useRef(null);
  const initialMousePosRef = useRef({ x: 0, y: 0 });
  const itemRefs = useRef({});
  const programmaticScrollActiveRef = useRef(false);
  const targetScrollRef = useRef(0);

  useEffect(() => {
    if (!allProjects) return;
    setVisibleProjects(
      allProjects.filter(p =>
        p.project_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [allProjects, searchTerm]);

  useImperativeHandle(ref, () => ({
    scrollAndFocusProject(projectID) {
      setFocusedProject(projectID);
      const container = document.querySelector('.projects-scroll-container');
      const item = itemRefs.current[projectID];
      if (!container || !item) return;
      programmaticScrollActiveRef.current = true;
      targetScrollRef.current =
        item.getBoundingClientRect().top -
        container.getBoundingClientRect().top +
        container.scrollTop;
      container.scrollTo({ top: targetScrollRef.current, behavior: 'smooth' });
    }
  }));

  const openPopup = id =>
    window.open(`${window.location.origin}/content/?id=${id}`, '_blank', 'width=675,height=600');

  const handleMouseEnter = (e, projectID) => {
    initialMousePosRef.current = { x: e.clientX, y: e.clientY };
    hoverTimer.current = setTimeout(() => {
      setFocusedProject(projectID);
      hoverTimer.current = null;
    }, HOVER_DELAY);
  };

  const handleMouseMove = (e, projectID) => {
    if (focusedProject === projectID) return;
    const dx = Math.abs(e.clientX - initialMousePosRef.current.x);
    const dy = Math.abs(e.clientY - initialMousePosRef.current.y);
    if (dx > MOVEMENT_THRESHOLD || dy > MOVEMENT_THRESHOLD) {
      if (hoverTimer.current) {
        clearTimeout(hoverTimer.current);
        hoverTimer.current = null;
      }
      initialMousePosRef.current = { x: e.clientX, y: e.clientY };
      hoverTimer.current = setTimeout(() => {
        setFocusedProject(projectID);
        hoverTimer.current = null;
      }, HOVER_DELAY);
    }
  };

  const handleMouseLeave = () => {
    if (hoverTimer.current) {
      clearTimeout(hoverTimer.current);
      hoverTimer.current = null;
    }
    setFocusedProject(null);
    programmaticScrollActiveRef.current = false;
  };

  useEffect(() => {
    const container = document.querySelector('.projects-scroll-container');
    const handleScroll = () => {
      if (!programmaticScrollActiveRef.current) {
        if (hoverTimer.current) {
          clearTimeout(hoverTimer.current);
          hoverTimer.current = null;
        }
        setFocusedProject(null);
      }
    };
    const handleWheel = () => {
      setFocusedProject(null);
      programmaticScrollActiveRef.current = false;
    };
    if (container) {
      container.addEventListener('scroll', handleScroll);
      container.addEventListener('wheel', handleWheel);
    }
    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
        container.removeEventListener('wheel', handleWheel);
      }
    };
  }, []);

  return (
    <>
      {error && <p className="status-message error">{error}</p>}
      <div className="projects-grid">
        <div className="grid-item intro-item">
          <div className="intro-content">
            {isTreatmentView ? (
              // Render full treatment text (without a local back button)
              <p className="intro-text" style={{ textAlign: 'left' }}>
                I’ve been writing treatments for over 15 years, making me one of the most <span className="intro-white">experienced</span> and creative writers in the industry.<br/><br/>
                No matter what genre, budget or timeline, I help <span className="intro-white">agencies</span>, <span className="intro-white">directors</span> and <span className="intro-white">production companies</span> maximise the impact of their pitches.<br/><br/>
                Writing <span className="intro-white">over 500 treatments</span> so far, I’ve worked with some of the biggest <span className="intro-white">directors</span> in the business, supporting scores of award winning projects.<br/><br/>
                I’ve written no less than three <span className="intro-white">Super Bowl</span> commercials, helped market two <span className="intro-white">Sundance</span> shortlisted features and even helped write for the <span className="intro-white">Oscars</span>.<br/><br/>
                Please feel free to browse the work below, or click <span className="intro-white">
                  <a href="/aboutMe/index.html" style={{ textDecoration: 'none', color: 'inherit' }}>here</a>
                </span> to find out more…
              </p>
            ) : (
              <>
                <p className="intro-text">
                  <span className="intro-white">Creative Producer, Content Specialist</span>
                  <span className="intro-grey"> and </span>
                  <span
                    className="intro-white"
                    style={{ cursor: 'pointer', fontWeight: 'bold' }}
                    onClick={() => {
                      if (onTreatmentView) onTreatmentView(true);
                      setExtraLinksVisible(true);
                    }}
                  >
                    Treatment Writer
                  </span>
                  <span className="intro-grey">
                    {' '}based in <span className="intro-white">Athens</span>, <span className="intro-white">Bali</span> and <span className="intro-white">London</span>.
                  </span>
                </p>
                <p></p>
                <div className="intro-links">
                  {!extraLinksVisible ? (
                    <span
                      className="intro-link"
                      onClick={() => setExtraLinksVisible(true)}
                      style={{ cursor: 'pointer', textDecoration: 'none', fontWeight: 'bold' }}
                    >
                      [More...]
                    </span>
                  ) : (
                    <>
                      <span
                        className="intro-link"
                        onClick={() => {
                          if (onTreatmentView) onTreatmentView(true);
                          setExtraLinksVisible(true);
                        }}
                        style={{ cursor: 'pointer', textDecoration: 'none', fontWeight: 'bold' }}
                      >
                        Treatment Writing
                      </span>
                      <span>+</span>
                      <a href="/otherWork/index.html" className="intro-link">Branded Content</a>
                      <span>+</span>
                      <a href="/aboutMe/index.html" className="intro-link">About Me</a>
                      <span>+</span>
                      <a href="mailto:arran.ig@gmail.com" className="intro-link">Contact</a>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>

        {visibleProjects.map((proj, idx) => (
          <GridItem
            key={proj.project_id}
            ref={el => (itemRefs.current[proj.project_id] = el)}
            project={proj}
            idx={idx}
            onItemClick={openPopup}
            onItemMouseEnter={handleMouseEnter}
            onItemMouseMove={handleMouseMove}
            onItemMouseLeave={handleMouseLeave}
            opacity={focusedProject && focusedProject !== proj.project_id ? 0.4 : 1}
          />
        ))}
      </div>
    </>
  );
});

export default ProjectsGrid;