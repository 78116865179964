// src/App.js
import React, { useRef, useState, useEffect } from 'react';
import './App.css';
import Sidebar from './components/Sidebar';
import TopNavbar from './components/TopNavbar';
import ProjectsGrid from './components/ProjectsGrid';
import PhoneHeader from './components/PhoneHeader';
import PhoneMenu from './components/PhoneMenu';

function App() {
  const [allProjects, setAllProjects] = useState([]);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [isPhoneMenuOpen, setIsPhoneMenuOpen] = useState(false);
  const [isTreatmentView, setIsTreatmentView] = useState(false);
  // State to control UI flash-in for both desktop and phone views
  const [uiReady, setUiReady] = useState(false);
  const projectsGridRef = useRef(null);

  useEffect(() => {
    // Simulate UI readiness (could also tie to window load event if needed)
    setUiReady(true);
  }, []);

  useEffect(() => {
    const isLocalhost = window.location.hostname === "localhost";
    const apiUrl = isLocalhost
      ? "http://localhost:8000/gateway.php?action=getProjects"
      : window.location.origin + "/backend/public/gateway.php?action=getProjects";
  
    fetch(apiUrl)
      .then((res) => {
        if (!res.ok) throw new Error(`HTTP error! status: ${res.status}`);
        return res.json();
      })
      .then((data) => {
        setAllProjects(data);
        setLoaded(true);
      })
      .catch((err) => {
        setError(err.message);
        setLoaded(true);
      });
  }, []);

  const handleSidebarHoverProject = (id) => {
    projectsGridRef.current?.scrollAndFocusProject(id);
  };

  const handleSearchChange = (term) => {
    setSearchTerm(term);
  };

  // Toggle phone menu only if not in treatment view.
  const togglePhoneMenu = () => {
    if (isTreatmentView) {
      setIsTreatmentView(false);
    } else {
      setIsPhoneMenuOpen((prev) => !prev);
    }
  };

  // Check if phone view based on window width.
  const isPhone = window.innerWidth <= 768;

  return (
    <div
      className="app-wrapper"
      style={{ opacity: uiReady ? 1 : 0, transition: 'opacity 0.3s ease' }}
    >
      {isPhone ? (
        <>
          <PhoneHeader
            onMenuClick={togglePhoneMenu}
            isMenuOpen={isPhoneMenuOpen}
            isTreatmentView={isTreatmentView}
          />
          <div className="main-content">
            <section className="right-column">
              <div className="projects-scroll-container">
                {isPhoneMenuOpen ? (
                  <PhoneMenu
                    allProjects={allProjects}
                    error={error}
                    onHoverProject={handleSidebarHoverProject}
                    onSearchChange={handleSearchChange}
                  />
                ) : (
                  loaded && (
                    <ProjectsGrid
                      ref={projectsGridRef}
                      allProjects={allProjects}
                      searchTerm={searchTerm}
                      error={error}
                      isTreatmentView={isTreatmentView}
                      onTreatmentView={setIsTreatmentView}
                    />
                  )
                )}
              </div>
            </section>
          </div>
        </>
      ) : (
        <>
          <div className="main-content">
            <aside className="left-column">
              <Sidebar
                allProjects={allProjects}
                error={error}
                onHoverProject={handleSidebarHoverProject}
                onSearchChange={handleSearchChange}
              />
            </aside>
            <section className="right-column">
              <TopNavbar />
              <div className="projects-scroll-container">
                {loaded && (
                  <ProjectsGrid
                    ref={projectsGridRef}
                    allProjects={allProjects}
                    searchTerm={searchTerm}
                    error={error}
                    isTreatmentView={isTreatmentView}
                    onTreatmentView={setIsTreatmentView}
                  />
                )}
              </div>
            </section>
          </div>
        </>
      )}
    </div>
  );
}

export default App;