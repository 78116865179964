// src/components/PhoneHeader.js
import React from 'react';
import './PhoneHeader.css';
import Logo from './Logo';

const MenuChevron = () => (
  <svg
    className="chevron-icon menu-chevron"
    width="32"
    height="32"
    viewBox="0 0 24 24"
  >
    <polyline
      points="6 9 12 15 18 9"
      fill="none"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const BackChevron = () => (
  <svg
    className="chevron-icon back-chevron"
    width="32"
    height="32"
    viewBox="0 0 24 24"
  >
    <polyline
      points="6 9 12 15 18 9"
      fill="none"
      stroke="currentColor"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

function PhoneHeader({ onMenuClick, isMenuOpen, isTreatmentView }) {
  return (
    <header className="phone-header">
      <div className="phone-header-logo">
        <Logo />
      </div>
      <button
        className="phone-header-menu-button"
        onClick={onMenuClick}
        aria-label={isTreatmentView ? "Back" : isMenuOpen ? "Close menu" : "Open menu"}
      >
        {isTreatmentView || isMenuOpen ? (
          <>
            <BackChevron />
            <span className="button-text">Back</span>
          </>
        ) : (
          <>
            <span className="button-text">Menu</span>
            <MenuChevron />
          </>
        )}
      </button>
    </header>
  );
}

export default PhoneHeader;