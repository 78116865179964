// src/components/PhoneMenu.js
import React, { useRef, useState } from 'react';
import './PhoneMenu.css';

function PhoneMenu({
  allProjects,
  error,
  onHoverProject,
  onSearchChange,
}) {
  const [searchTerm, setSearchTerm] = useState('');
  const hoverTimer = useRef(null);

  const handleSearchChange = (e) => {
    const val = e.target.value.toUpperCase();
    setSearchTerm(val);
    if (onSearchChange) {
      onSearchChange(val);
    }
  };

  const openContentWindow = (projectID) => {
    const popupURL = window.location.origin + `/content/?id=${projectID}`;
    const windowFeatures = "width=675,height=600,resizable=yes,scrollbars=yes";
    window.open(popupURL, '_blank', windowFeatures);
  };

  // Filter projects by search term
  const filteredProjects = (allProjects || []).filter(project =>
    project.project_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="phone-menu-overlay">
      <div className="phone-menu-inner">
        {/* Search box */}
        <div className="phone-menu-search-container">
          <input
            type="text"
            placeholder="SEARCH PROJECTS..."
            className="phone-menu-search"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>

        {/* Show static menu links only when searchTerm is empty */}
        {!searchTerm && (
          <ul className="phone-menu-links">
            <li onClick={() => window.open('/aboutMe/index.html', '_blank')}>
              ABOUT ME
            </li>
            <li onClick={() => window.open('/aboutMe/index.html', '_blank')}>
              ADVERTISING TREATMENTS
            </li>
            <li onClick={() => window.open('/otherWork/index.html', '_blank')}>
              FORMATS & CONTENT
            </li>
            <li onClick={() => window.open('/software/index.html', '_blank')}>
              SOFTWARE DEVELOPMENT
            </li>
            <li onClick={() => window.location.href = 'mailto:arran.igoe@gmail.com'}>
              CONTACT
            </li>
            <li className="phone-menu-categories-title">
              CATEGORIES
            </li>
          </ul>
        )}

        {error && <p className="status-message error">{error.toUpperCase()}</p>}

        <ul className="phone-menu-projects">
          {filteredProjects.map(project => (
            <li
              key={project.project_id}
              className="phone-menu-project-item"
              onMouseEnter={() => {
                hoverTimer.current = setTimeout(() => {
                  if (onHoverProject) {
                    onHoverProject(project.project_id);
                  }
                }, 500);
              }}
              onMouseLeave={() => {
                clearTimeout(hoverTimer.current);
              }}
              onClick={() => openContentWindow(project.project_id)}
            >
              {project.project_name.toUpperCase()}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default PhoneMenu;