// src/components/TopNavbar.js
import React from 'react';
import './TopNavbar.css';

// Helper function to build a dynamic URL relative to the current origin.
// This function appends the provided relativePath to the current window.location.origin.
const getPopupURL = (relativePath) => {
  return window.location.origin + relativePath;
};

function openAboutMeWindow() {
  // Build URL dynamically
  const url = getPopupURL('/aboutMe/index.html');
  const windowFeatures = "width=900,height=600,resizable=yes,scrollbars=yes";
  window.open(url, '_blank', windowFeatures);
}

function openFormatsAndContentWindow() {
  const url = getPopupURL('/otherWork/index.html');
  const windowFeatures = "width=900,height=600,resizable=yes,scrollbars=yes";
  window.open(url, '_blank', windowFeatures);
}

function openSoftwareWindow() {
  const url = getPopupURL('/software/index.html');
  const windowFeatures = "width=900,height=600,resizable=yes,scrollbars=yes";
  window.open(url, '_blank', windowFeatures);
}

const navItems = [
  { label: 'TREATMENT WRITING', selected: true, action: null },
  { label: 'ABOUT ME', selected: false, action: openAboutMeWindow },
  { label: 'FORMATS & CONTENT', selected: false, action: openFormatsAndContentWindow },
  { label: 'SOFTWARE DEVELOPMENT', selected: false, action: openSoftwareWindow },
  { label: 'CONTACT', selected: false, action: () => window.location.href = 'mailto:arran.igoe@gmail.com' }
];

const TopNavbar = () => {
  return (
    <nav className="top-navbar">
      {navItems.map((item, idx) => (
        <div
          key={idx}
          className={`nav-item ${item.selected ? 'selected' : ''}`}
          onClick={item.action ? item.action : undefined}
          style={{ cursor: item.action ? 'pointer' : 'default' }}
        >
          {item.label}
        </div>
      ))}
    </nav>
  );
};

export default TopNavbar;