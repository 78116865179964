// src/components/Sidebar.js
import React, { useState, useRef } from 'react';
import './Sidebar.css';
import Logo from './Logo';

const Sidebar = ({ allProjects, error, onHoverProject, onSearchChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const hoverTimer = useRef(null);

  const handleSearchChange = (e) => {
    const val = e.target.value.toUpperCase();
    setSearchTerm(val);
    if (onSearchChange) {
      onSearchChange(val);
    }
  };

  // Filter out projects based on searchTerm
  const filteredProjects = (allProjects || []).filter(project =>
    project.project_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Open content in new popup (limited width: 675px)
  const openContentWindow = (projectID) => {
    const popupURL = window.location.origin + `/content/?id=${projectID}`;
    const windowFeatures = "width=675,height=600,resizable=yes,scrollbars=yes";
    window.open(popupURL, '_blank', windowFeatures);
  };

  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-header">
        <Logo />
        <input
          type="text"
          placeholder="SEARCH..."
          className="sidebar-search"
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ color: searchTerm ? 'white' : '#3a3a3a' }}
        />
      </div>
      <ul className="sidebar-list">
        {/* If you wanted to display an error: */}
        {error && <p className="status-message error">{error.toUpperCase()}</p>}

        {filteredProjects.map(project => (
          <li
            key={project.project_id}
            className="sidebar-item"
            onMouseEnter={() => {
              hoverTimer.current = setTimeout(() => {
                if (onHoverProject) {
                  onHoverProject(project.project_id);
                }
              }, 500);
            }}
            onMouseLeave={() => {
              clearTimeout(hoverTimer.current);
            }}
            onClick={() => openContentWindow(project.project_id)}
          >
            {project.project_name.toUpperCase()}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;