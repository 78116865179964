// src/components/Logo.js
import React from 'react';

const Logo = () => {
  return (
    <div className="logo-container">
      <img
        src="/images/logo.svg"
        alt="Arran Igoe Logo"
        className="logo"
      />
    </div>
  );
};


// // export default Logo;
// // src/components/Logo.js
// import React from 'react';
// import AnimatedLogo from './AnimatedLogo';

// const Logo = ({ texture }) => {
//   // Pass the texture prop to choose "camo" or "plain"
//   return <AnimatedLogo texture={texture} />;
// };



export default Logo;